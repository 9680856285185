import Client from './clients/axios-client';
const resource = '/api';

export default {
  get(userId) {
    return Client.get(`${resource}/users/basic/${userId}`);
  },
  getUserById(id) {
    return Client.get(`${resource}/users/${id}`);
  },
  getProfile() {
    return Client.get(`${resource}/users/profile`);
  },
  getUsersByStatusId(statusId) {
    return Client.get(`${resource}/user/users/status/${statusId}`);
  },
  getUsersByStatusIdBatch(param) {
    return Client.get(`${resource}/user/users/status/${param.index}/${param.size}/${param.statusId}`);
  },
  getUsers() {
    return Client.get(`${resource}/user/users`);
  },
  getSkills() {
    return Client.get(`${resource}/users/allskills`);
  },
  updateUserStatus(request) {
    return Client.patch(`${resource}/user/users/statusupdate`, request);
  },
  deleteUser(id) {
    return Client.delete(`${resource}/user/users/${id}`);
  },
  updateUserProfile(request) {
    return Client.patch(`${resource}/users/${request.UserID}`, request);
  },
  updateUserSkills(request) {
    return Client.patch(`${resource}/users/skills/${request.id}`, request.payload);
  },
  updateUserExperiences(request) {
    return Client.patch(`${resource}/users/experiences/${request.id}`, request.payload);
  },
  searchTalent(name) {
    return Client.get(`${resource}/user/talents/name/${name}/`);
  },
  searchClient(name) {
    return Client.get(`${resource}/user/clients/name/${name}/`);
  },
  updateSalaryRange(request) {
    return Client.patch(`${resource}/users/salary/${request.id}`, request.payload);
  },
  addFile(request) {
    const formData = new FormData();
    request.Files.forEach((file) => { formData.append('files', file); });
    return Client.post(`${resource}/user/users/files`, formData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  removeFile(fileid) {
    return Client.delete(`${resource}/user/users/files/${fileid}`);
  },
  getFile(fileid) {
		return Client.get(`${resource}/user/users/files/${fileid}`, {
			responseType: 'blob'
		});
	},
  getTalentRatings(id) {
    return Client.get(`${resource}/user/talents/${id}/rating`);
  },
  getTally() {
    return Client.get(`${resource}/users/admin/count`);
  },
  getRegistrationCount() {
    return Client.get(`${resource}/users/reg/count`);
  },
  getCountryTally() {
    return Client.get(`${resource}/users/country/count`);
  },
  uploadAvatar(file) {
    const formData = new FormData();
    formData.append('files', file);
    return Client.put(`${resource}/user/users/profile-img`, formData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getAvatar() {
    return Client.get(`${resource}/user/users/profile-img`);
  },
  addCommunity(id) {
    return Client.patch(`${resource}/users/community/${id}`);
  },
  removeCommunity(id) {
    return Client.delete(`${resource}/users/community/${id}`);
  },
  applyForCertificate(userId, firstName, lastName, certificateName, certId) {
    return Client.post(`${resource}/users/apply-certificate`, {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      certificateName: certificateName,
      certId: certId
    });
  },
  applyForCourse(userId, firstName, lastName, email, courseTitle, certificateID) {
    return Client.post(`${resource}/users/apply-course`, {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      courseTitle: courseTitle,
      certificateID: certificateID 
    });
  },
  getAppliedCourses() {
    return Client.get(`${resource}/users/applied-courses`);
  },
  getUserCertificates() {
    return Client.get(`${resource}/users/user-certificates`);
  }
  
};